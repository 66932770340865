<template>
  <v-form ref="form" class="claim-form" autocomplete="off">
    <div class="claim-form__section-wrapper">
      <FormGroup :title="$t('ui.injuredPersonInfo')" titleTransform="uppercase">
        <ClaimantInfo v-bind="claimantInfoFormProps" @fieldInput="handleFormSectionFieldInput" />
        <ClaimantSubInfo v-bind="claimantInfoFormProps" @fieldInput="handleFormSectionFieldInput" />
      </FormGroup>
    </div>
    <div class="claim-form__section-wrapper">
      <FormGroup
        :title="$t('ui.primaryHealthInsurance')"
        titleTransform="uppercase"
        subtitle="Primary Health Insurance MUST be filed prior to this policy."
        noSubtitleBottomMargin
      >
        <InsuranceInfo
          v-bind="healthInsuranceInfoFormProps"
          @fieldInput="handleFormSectionFieldInput"
        />
      </FormGroup>
    </div>
    <div v-if="this[HEALTH_FIELD_ALIASES.HEALTH_CLAIMANT_HAS_HEALTHCARE]">
      <FormGroup
        class="claim-form__section claim-form__section--smallerTitle"
        :title="healthInsuranceInfoFormProps.shown ? $t('ui.healthInsurancePlan') : ''"
        titleTransform="capitalize"
        titleSize="1.2rem"
        titleWeight="400"
      >
        <HealthInsuranceInfo
          v-bind="healthInsuranceInfoFormProps"
          :hasEnroll="this[HEALTH_FIELD_ALIASES.HEALTH_CLAIMANT_HAS_HEALTHCARE]"
          @fieldInput="handleFormSectionFieldInput"
          @addForm="handleAddHealthInsuranceForm"
          @deleteForm="handleDeleteHealthInsuranceForm"
        />
      </FormGroup>
    </div>
    <div class="claim-form__section-wrapper">
      <FormGroup :title="$t('ui.timePlaceDetailsIncident')" titleTransform="uppercase">
        <IncidentDetails
          v-bind="incidentDetailsFormProps"
          @fieldInput="handleFormSectionFieldInput"
        />
      </FormGroup>
    </div>
    <DateSpan :label="$t('ui.currentDate')" />
  </v-form>
</template>

<script>
import { claimFormNagha, syncForm, validateForm } from '@/mixins';
import ClaimantInfo from '@/components/forms/ClaimFormNagha/partials/ClaimantInfo';
import ClaimantSubInfo from '@/components/forms/ClaimFormNagha/partials/ClaimantSubInfo';
import FormGroup from '@/components/FormGroup';
import InsuranceInfo from '@/components/forms/ClaimFormNagha/partials/InsuranceInfo';
import HealthInsuranceInfo from '@/components/forms/ClaimFormNagha/partials/HealthInsuranceInfo';
import IncidentDetails from '@/components/forms/ClaimFormNagha/partials/IncidentDetails';
import DateSpan from '@/components/typography/DateSpan';

// const FormSection = () => import('@/components/FormSection');

export default {
  name: 'ClaimFormNagha',
  mixins: [claimFormNagha, syncForm, validateForm],
  components: {
    ClaimantInfo,
    ClaimantSubInfo,
    FormGroup,
    InsuranceInfo,
    HealthInsuranceInfo,
    IncidentDetails,
    DateSpan,
  },
  computed: {
    subInfoLabel() {
      return this.$t('ui.claimantAddress');
    },
  },
  methods: {
    handleAddHealthInsuranceForm() {
      this.$emit('addHealthInsuranceForm');
    },
    handleDeleteHealthInsuranceForm(formKey) {
      this.$emit('deleteHealthInsuranceForm', formKey);
    },
    handleFormSectionFieldInput({ field, value }) {
      this.handleFieldInput(field, value);
    },
  },
};
</script>

<style lang="scss" scoped>
.claim-form__section-wrapper {
  &:nth-child(1n + 2) {
    margin-top: 45px;
  }
}
.claim-form__section {
  &--smallerTitle {
    .form-group-title {
      font-size: 1rem;
    }
  }
}
</style>
