<template>
  <v-container pa-0 fluid>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STREET, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STREET]"
          :rules="['required']"
          :label="$t('fields.addressFirst')"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="
            handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_ADDITIONAL, arguments[0])
          "
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_ADDITIONAL]"
          :label="$t('fields.addressSecond')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_CITY, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_CITY]"
          :rules="['required']"
          :label="$t('fields.city')"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_ZIP, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_ZIP]"
          :rules="['required']"
          :label="$t('fields.zip')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          @input="
            val => {
              this[CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_COUNTRY] &&
                handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STATE, null);
              handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_COUNTRY, val);
            }
          "
          :items="countriesOptions"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_COUNTRY]"
          :rules="['required']"
          :placeholder="$t('fields.country')"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          :disabled="!this[CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_COUNTRY]"
          :items="statesOptions[this[CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_COUNTRY]]"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STATE, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STATE]"
          :rules="['required']"
          :placeholder="$t('fields.state')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_EMAIL, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_EMAIL]"
          :rules="['required', 'email']"
          :label="$t('fields.emailAddress')"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_HOME_PHONE, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_HOME_PHONE]"
          :rules="['phone']"
          type="tel"
          :label="$t('fields.homePhoneNumber')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_WORK_PHONE, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_WORK_PHONE]"
          :rules="['phone']"
          type="tel"
          :label="$t('fields.workPhoneNumber')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <FormSection :title="$t('fields.status')">
          <v-radio-group
            @change="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_STATUS, arguments[0])"
            :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_STATUS]"
            :rules="['required']"
            row
          >
            <v-radio value="SINGLE" input-value="SINGLE" :label="$t('fields.single')"></v-radio>
            <v-radio value="MARRIED" input-value="MARRIED" :label="$t('fields.married')"></v-radio>
            <v-radio
              value="FULL_TIME_STUDENT"
              input-value="FULL-TIME STUDENT"
              :label="$t('fields.fullTimeStudent')"
            ></v-radio>
          </v-radio-group>
        </FormSection>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12">
        <FormSection :title="$t('ui.isInjuredPersonMedicaidBeneficiary')">
          <v-radio-group
            @change="
              handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_MEDICAID_BENEFICIARY, arguments[0])
            "
            :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_MEDICAID_BENEFICIARY]"
            :rules="['requiredBoolean']"
            row
          >
            <v-radio :value="true" :label="$t('ui.yes')"></v-radio>
            <v-radio :value="false" :label="$t('ui.no')"></v-radio>
          </v-radio-group>
        </FormSection>
      </v-col>
    </v-row>
    <v-row v-if="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_MEDICAID_BENEFICIARY]">
      <v-col cols="12" md="12">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_SS_NOR_HID_NUMBER, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_SS_NOR_HID_NUMBER]"
          :rules="['required']"
          :placeholder="$t('ui.ssnOrHIDNumber')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { syncForm, withCountryStateOptions } from '@/mixins';
import { genders, organizationRoles } from '@/utils/options';
import { getClaimantInfoFormProps } from '@/utils/helpers/app/claimsNagha';

const FormSection = () => import('@/components/FormSection');

export default {
  name: 'ClaimantSubInfo',
  mixins: [syncForm, withCountryStateOptions],
  components: {
    FormSection,
  },
  props: {
    ...getClaimantInfoFormProps(),
  },
  data() {
    return {
      selectOptions: {
        genders,
        organizationRoles,
      },
    };
  },
};
</script>
