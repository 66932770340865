<template>
  <div>
    <Preloader :value="organizationPageParams.loading" fullscreen />
    <component
      :pageTitle="pageTitle"
      :organizationPageParams="organizationPageParams"
      :is="currentComponent"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { organizationPageParams as organizationPageParamsMixin } from '@/mixins';
import { ANALYTIC_EVENTS } from '@/utils/constants';
import SubmitFnol from '@/components/claims/Submit/SubmitFnol.vue';
import SubmitFnolOlympic from '@/components/claims/Submit/SubmitFnolOlympic.vue';
import SubmitFnolUsasa from '@/components/claims/Submit/SubmitFnolUsasa.vue';
import SubmitFnolFysa from '@/components/claims/Submit/SubmitFnolFysa.vue';
import SubmitFnolNagha from '@/components/claims/Submit/SubmitFnolNagha.vue';
import SubmitFnolColorado from '@/components/claims/Submit/SubmitFnolColorado.vue';
import SubmitFnolCrawford from '@/components/claims/Submit/SubmitFnolCrawford';
import Preloader from '@/components/Preloader';

export default {
  name: 'SubmitFnol',
  components: {
    Preloader,
  },
  mixins: [organizationPageParamsMixin],
  computed: {
    currentComponent() {
      if (this.organizationPageParams.form === 'DEFAULT') {
        return SubmitFnol;
      }
      if (this.organizationPageParams.form === 'OLYMPIC' || this.organizationPageParams.olympic) {
        return SubmitFnolOlympic;
      }
      if (this.organizationPageParams.form === 'USASA') {
        return SubmitFnolUsasa;
      }
      if (this.organizationPageParams.form === 'FYSA') {
        return SubmitFnolFysa;
      }
      if (this.organizationPageParams.form === 'NAGHA') {
        return SubmitFnolNagha;
      }
      if (this.organizationPageParams.form === 'COLORADOMTB') {
        return SubmitFnolColorado;
      }
      if (this.organizationPageParams.form === 'CRAWFORD') {
        return SubmitFnolCrawford;
      }
      return SubmitFnol;
    },
  },
  methods: {
    ...mapActions('analytics', ['logEvent']),
    async initSubmitForm() {
      await this.initOrganizationPageParams('sportsUnion');
      this.logEvent({
        event: ANALYTIC_EVENTS.CLAIMANT_OPEN_FNOL,
        data: this.organizationPageParams.organizationId,
      });
    },
  },
  mounted() {
    this.initSubmitForm();
  },
};
</script>

<style lang="scss" scoped></style>
