<template>
  <v-container pa-0 fluid>
    <v-row>
      <v-col cols="12" md="6">
        <PhDatepicker
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_DATE, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_DATE]"
          :hideDetails="false"
          placeholder="Date of incident"
          fullWidth
        />
      </v-col>
      <v-col cols="12" md="6">
        <PhTimepicker
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_TIME, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_TIME]"
          :hideDetails="false"
          placeholder="Time of incident"
          fullWidth
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_LOCATION, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_LOCATION]"
          label="Location of incident"
        />
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="12">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INDICATION_TEETH_INVOLVED, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INDICATION_TEETH_INVOLVED]"
          label="Indicate which Teeth were Involved in the Accident"
        />
      </v-col>
      <v-col cols="12">
        <v-select
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.DESCRIBTION_TEETH_INVOLVED, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.DESCRIBTION_TEETH_INVOLVED]"
          :items="selectOptions.conditionsOfInjuredTeeth"
          label="Describe Condition of Injured Teeth Prior to Accident"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <FormSection title="Body part injured">
          <v-select
            @input="
              handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_BODY_PART_INJURED, arguments[0])
            "
            :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_BODY_PART_INJURED]"
            :items="selectOptions.bodyParts"
            placeholder="Select"
          />
        </FormSection>
      </v-col>
      <v-col cols="12" md="6">
        <FormSection title="Body side injured">
          <v-select
            @input="
              handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_BODY_SIDE_INJURED, arguments[0])
            "
            :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_BODY_SIDE_INJURED]"
            :items="selectOptions.bodySides"
            placeholder="Select"
          />
        </FormSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <FormSection title="Type of injury">
          <v-select
            @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_TYPE, arguments[0])"
            :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_TYPE]"
            :items="selectOptions.injuryTypes"
            placeholder="Select"
          />
        </FormSection>
      </v-col>
      <v-col cols="12" md="6">
        <FormSection title="Playing Surface">
          <v-select
            @input="handleFieldInput(INCIDENT_FIELD_ALIASES.PLAYING_SURFACE, arguments[0])"
            :value="this[INCIDENT_FIELD_ALIASES.PLAYING_SURFACE]"
            :items="selectOptions.olympicPlayingSurfaces"
            placeholder="Select"
          />
        </FormSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <FormSection title="Did Injury Result in Death?">
          <v-radio-group
            @change="
              handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_RESULT_IN_DEATH, arguments[0])
            "
            :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_RESULT_IN_DEATH]"
            :rules="['requiredBoolean']"
            row
          >
            <v-radio :value="true" label="Yes"></v-radio>
            <v-radio :value="false" label="No"></v-radio>
          </v-radio-group>
        </FormSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_DESCRIPTION, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_DESCRIPTION]"
          :rules="['required']"
          label="Describe what happened"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <FormSection title="Event type">
          <v-select
            @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_EVENT_TYPE, arguments[0])"
            :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_EVENT_TYPE]"
            :items="selectOptions.eventTypes"
            placeholder="Select"
          />
        </FormSection>
      </v-col>
      <v-col cols="12" md="6" class="d-flex align-end">
        <FormSection title="Name of team">
          <v-select
            @input="handleFieldInput(INCIDENT_FIELD_ALIASES.NAME_OF_TEAM, arguments[0])"
            :value="this[INCIDENT_FIELD_ALIASES.NAME_OF_TEAM]"
            :items="selectOptions.olympicTeamNames"
            placeholder="Select"
          />
        </FormSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <FormSection title="Contact or Non-Contact?" required>
          <v-select
            @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_CONTACT, arguments[0])"
            :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_CONTACT]"
            :items="selectOptions.injuryContacts"
            :rules="['requiredBoolean']"
            placeholder="Select"
          />
        </FormSection>
      </v-col>
      <v-col cols="12" md="6">
        <FormSection
          :required="this[INCIDENT_FIELD_ALIASES.INCIDENT_CONTACT]"
          title="Type of contact"
        >
          <v-select
            @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_CONTACT_TYPE, arguments[0])"
            :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_CONTACT_TYPE]"
            :items="selectOptions.injuryContactTypes"
            :rules="this[INCIDENT_FIELD_ALIASES.INCIDENT_CONTACT] ? ['required'] : []"
            placeholder="Select"
          />
          <div v-if="isOther(this[INCIDENT_FIELD_ALIASES.INCIDENT_CONTACT_TYPE])" class="mt-2">
            <v-textarea
              @input="
                handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_OTHER_CONTACT_TYPE, arguments[0])
              "
              :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_OTHER_CONTACT_TYPE]"
              label="Other contact type"
              outlined
            />
          </div>
        </FormSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-textarea
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_NOTE, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_NOTE]"
          label="Note"
          outlined
        />
      </v-col>
    </v-row>
    <v-row v-if="!fieldIsHidden(INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_MEDICAL_PAYMENTS)">
      <v-col cols="12">
        <div class="incident-details-form__checkbox-wrapper">
          <v-checkbox
            :rules="['required']"
            @change="
              handleFieldInput(
                INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_MEDICAL_PAYMENTS,
                arguments[0] || false
              )
            "
            :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_MEDICAL_PAYMENTS]"
            :input-value="this[INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_MEDICAL_PAYMENTS]"
            label="I authorized medical payments to physician or supplier for services described on any attached statements enclosed."
          />
        </div>
      </v-col>
    </v-row>
    <v-row v-if="!fieldIsHidden(INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_HERIBAL_ANY_INSURANCE)">
      <v-col cols="12">
        <div class="incident-details-form__checkbox-wrapper">
          <v-checkbox
            :rules="['required']"
            @change="
              handleFieldInput(
                INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_HERIBAL_ANY_INSURANCE,
                arguments[0] || false
              )
            "
            :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_HERIBAL_ANY_INSURANCE]"
            :input-value="this[INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_HERIBAL_ANY_INSURANCE]"
            label="I hereby authorize any insurance company, hospital, physician or other person who has attended or examined the claimant to disclose when requested to do so, all information with respect to any injury, policy coverage, medical history, consultation, prescription or treatment, and copies of all hospital or medical records. A photo static copy of this authorization shall be considered as effective and valid as the original."
          />
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="!fieldIsHidden(INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_HERIBAL_ANY_COMMUNICATIONS)"
    >
      <v-col cols="12">
        <div class="incident-details-form__checkbox-wrapper">
          <v-checkbox
            :rules="['required']"
            @change="
              handleFieldInput(
                INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_HERIBAL_ANY_COMMUNICATIONS,
                arguments[0] || false
              )
            "
            :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_HERIBAL_ANY_COMMUNICATIONS]"
            :input-value="
              this[INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_HERIBAL_ANY_COMMUNICATIONS]
            "
            label="I hereby authorize any communication between USA Rugby and A-G Administrators in regards to the above mentioned injury."
          />
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_VICTIM_SIGNATURE, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_VICTIM_SIGNATURE]"
          :rules="['required']"
          label="Claimant Signature"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { hiddenFormFields, syncForm } from '@/mixins';
import {
  conditionsOfInjuredTeeth,
  bodyParts,
  bodySides,
  eventTypes,
  injuryContacts,
  injuryContactTypes,
  injuryTypes,
  olympicTeamNames,
  olympicPlayingSurfaces,
} from '@/utils/options';
import { getIncidentDetailsFormProps } from '@/utils/helpers/app/claimsOlympic';
import FormSection from '@/components/FormSection';
import PhDatepicker from '@/components/pickers/PhDatepicker';
import PhTimepicker from '@/components/pickers/PhTimepicker';

export default {
  name: 'IncidentDetails',
  mixins: [hiddenFormFields, syncForm],
  components: {
    FormSection,
    PhDatepicker,
    PhTimepicker,
  },
  props: {
    ...getIncidentDetailsFormProps(),
  },
  data() {
    return {
      selectOptions: {
        conditionsOfInjuredTeeth,
        bodyParts,
        bodySides,
        eventTypes,
        injuryContacts,
        injuryContactTypes,
        injuryTypes,
        olympicTeamNames,
        olympicPlayingSurfaces,
      },
    };
  },
  methods: {
    isOther(val) {
      return val === 'OTHER';
    },
  },
};
</script>

<style lang="scss">
// .incident-details-form__checkbox-wrapper {
//   &--single {
//     max-width: 550px;
//   }
// }
</style>
