<template>
  <v-container pa-0 fluid>
    <v-row>
      <v-col cols="12" md="6">
        <PhDatepicker
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_DATE, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_DATE]"
          :hideDetails="false"
          :placeholder="$t('fields.dateOfIncident')"
          fullWidth
        />
      </v-col>
      <v-col cols="12" md="6">
        <PhTimepicker
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_TIME, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_TIME]"
          :hideDetails="false"
          :placeholder="$t('fields.timeOfIncident')"
          fullWidth
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_LOCATION, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_LOCATION]"
          :placeholder="$t('fields.locationOfIncident')"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_BODY_PART_INJURED, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_BODY_PART_INJURED]"
          :items="selectOptions.bodyParts"
          :placeholder="$t('fields.bodyPartInjured')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_BODY_SIDE_INJURED, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_BODY_SIDE_INJURED]"
          :items="selectOptions.bodySides"
          :placeholder="$t('fields.bodySideInjured')"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_TYPE, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_TYPE]"
          :items="selectOptions.injuryTypes"
          :placeholder="$t('fields.injuryType')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_OCCURED_ON, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_OCCURED_ON]"
          :items="['INDOOR', 'OUTDOOR']"
          :placeholder="$t('fields.injuryOccuredOn')"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="
            handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_EVENT_NAME_AND_TYPE, arguments[0])
          "
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_EVENT_NAME_AND_TYPE]"
          :rules="['required']"
          :placeholder="$t('fields.nameAndTypeOfEvent')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_OCCURED_AT, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_OCCURED_AT]"
          :items="selectOptions.incidentOccuredAtLocations"
          :rules="['required']"
          :placeholder="$t('fields.injuryOccuredAt')"
        />
      </v-col>
    </v-row>
    <v-row v-if="isOther(this[INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_OCCURED_AT])">
      <v-col cols="12">
        <v-text-field
          autocomplete="new-password"
          @input="
            handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_OCCURED_AT_OTHER, arguments[0])
          "
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_OCCURED_AT_OTHER]"
          :label="$t('fields.injuryOccuredAt')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          autocomplete="new-password"
          @input="
            handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_DESCRIPTION, arguments[0])
          "
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_DESCRIPTION]"
          :rules="['required']"
          :label="$t('fields.describeWhatHappend')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCEDENT_NAME_OF_TEAM, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCEDENT_NAME_OF_TEAM]"
          :label="$t('fields.nameOfTeam')"
          :rules="['required']"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="
            handleFieldInput(
              INCIDENT_FIELD_ALIASES.INCEDENT_STATE_ASSICOATION_AFFILATE,
              arguments[0]
            )
          "
          :value="this[INCIDENT_FIELD_ALIASES.INCEDENT_STATE_ASSICOATION_AFFILATE]"
          :label="$t('fields.stateAssociation')"
          :rules="['required']"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCEDENT_REGION, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCEDENT_REGION]"
          :label="$t('fields.leagueName')"
          :rules="['required']"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-textarea
          @input="handleFieldInput(INCIDENT_FIELD_ALIASES.INCIDENT_NOTE, arguments[0])"
          :value="this[INCIDENT_FIELD_ALIASES.INCIDENT_NOTE]"
          label="Note"
          outlined
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-checkbox
          class="mt-2"
          @change="
            handleFieldInput(
              INCIDENT_FIELD_ALIASES.INCEDENT_CLAIMANT_CHECKBOX,
              arguments[0] || false
            )
          "
          :input-value="this[INCIDENT_FIELD_ALIASES.INCEDENT_CLAIMANT_CHECKBOX]"
          :value="this[INCIDENT_FIELD_ALIASES.INCEDENT_CLAIMANT_CHECKBOX]"
          :rules="['required']"
        >
          <template v-slot:label>
            <span>{{ $t('messages.coloradomtbCheckboxMsg') }}</span>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="
            handleFieldInput(INCIDENT_FIELD_ALIASES.INCEDENT_CLAIMANT_SIGNATURE, arguments[0])
          "
          :value="this[INCIDENT_FIELD_ALIASES.INCEDENT_CLAIMANT_SIGNATURE]"
          :label="$t('fields.claimantSignature')"
          :rules="['required']"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { hiddenFormFields, syncForm } from '@/mixins';
import {
  incidentOccuredAtLocations,
  bodyParts,
  bodySides,
  eventTypes,
  injuryContacts,
  injuryContactTypes,
  injurySeverityTypes,
  injuryTypes,
  membershipTypes,
  playingSurfaces,
} from '@/utils/options';
import { getIncidentDetailsFormProps } from '@/utils/helpers/app/claimsUsasa';
import PhDatepicker from '@/components/pickers/PhDatepicker';
import PhTimepicker from '@/components/pickers/PhTimepicker';

export default {
  name: 'IncidentDetails',
  mixins: [hiddenFormFields, syncForm],
  components: {
    PhDatepicker,
    PhTimepicker,
  },
  props: {
    ...getIncidentDetailsFormProps(),
  },
  data() {
    return {
      selectOptions: {
        incidentOccuredAtLocations,
        bodyParts,
        bodySides,
        eventTypes,
        injuryContacts,
        injuryContactTypes,
        injurySeverityTypes,
        injuryTypes,
        membershipTypes,
        playingSurfaces,
      },
    };
  },
  methods: {
    isOther(val) {
      return val === 'OTHER';
    },
  },
};
</script>

<style lang="scss">
.incident-details-form__checkbox-wrapper {
  &--single {
    max-width: 550px;
  }
}
</style>
