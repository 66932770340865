<template>
  <div class="lang-toggler">
    <button
      class="lang-toggler__language"
      :class="{ 'lang-toggler__language--active': currentLang === lang }"
      type="button"
      v-for="lang in langs"
      :key="lang"
      @click="handleClick(lang)"
    >
      {{ lang }}
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'LangToggler',
  data() {
    return {
      langs: ['ESP', 'ENG'],
    };
  },
  computed: {
    ...mapGetters('app', ['currentLang']),
  },
  methods: {
    ...mapActions('app', ['updateAppLocale']),
    handleClick(lang) {
      this.$root.$i18n.locale = lang;
      this.updateAppLocale(lang);
    },
  },
};
</script>

<style lang="scss" scoped>
.lang-toggler__language {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  color: rgba(0, 73, 144, 0.39);
  padding: 0 5px;
  &:first-of-type {
    border-right: 1px solid #f1f1f1;
  }
  &--active {
    color: #ed1f24;
  }
}
</style>
