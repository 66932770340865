<template>
  <v-container pa-0 fluid>
    <v-row>
      <v-col cols="12">
        <FormWaiverDisclaimer />
      </v-col>
    </v-row>
    <v-row v-if="!fieldIsHidden(DISCLAIMERS_FIELD_ALIASES.READ_AND_AGREE_TO_CLAIMS_INFO)">
      <v-col cols="12">
        <v-checkbox
          @change="
            handleFieldInput(
              DISCLAIMERS_FIELD_ALIASES.READ_AND_AGREE_TO_CLAIMS_INFO,
              arguments[0] || false
            )
          "
          :input-value="this[DISCLAIMERS_FIELD_ALIASES.READ_AND_AGREE_TO_CLAIMS_INFO]"
          :value="this[DISCLAIMERS_FIELD_ALIASES.READ_AND_AGREE_TO_CLAIMS_INFO]"
          :rules="['required']"
        >
          <template v-slot:label>
            <span>I read and agree with the</span>
            <PhLink @click="handleLinkClick('claimsInfo')" class="ml-1" clickOnly>
              Fraud Statements
            </PhLink>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { hiddenFormFields, syncForm } from '@/mixins';
import { getDisclaimersFormProps } from '@/utils/helpers/app/claims';
import PhLink from '@/components/PhLink';

const FormWaiverDisclaimer = () => import('@/components/typography/FormWaiverDisclaimer');

export default {
  name: 'Disclaimers',
  mixins: [hiddenFormFields, syncForm],
  components: {
    PhLink,
    FormWaiverDisclaimer,
  },
  props: {
    ...getDisclaimersFormProps(),
  },
  methods: {
    handleLinkClick(linkType) {
      this.$emit(`${linkType}Click`);
    },
  },
};
</script>

<style lang="scss" scoped>
.disclaimer-text {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 17px;
  letter-spacing: 1px;
  color: #464646;
  &--red {
    color: #9a2d2e;
  }
}
</style>
