<template>
  <div class="fnol-submit-view fill-height">
    <Preloader :value="organizationPageParams.loading" fullscreen />
    <v-container
      v-if="organizationPageParams.ready"
      class="fnol-submit-view__container"
      fill-height
      align-start
      align-content-start
    >
      <v-row class="fnol-submit-view__header" no-gutters>
        <v-col>
          <div class="fnol-submit-view__header-logo-wrapper">
            <Logo :src="organizationPageParams.logoUrl" large />
          </div>
          <div v-if="pageTitle" class="fnol-submit-view__header-title-wrapper">
            <PageSubtitle>{{ pageTitle }}</PageSubtitle>
          </div>
          <div
            v-if="organizationPageParams.complianceWarning"
            class="fnol-submit-view__header-text-wrapper"
          >
            <p class="fnol-submit-view__header-text my-0">
              {{ organizationPageParams.complianceWarning }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row class="fnol-submit-view__body mt-4 mt-md-10" no-gutters>
        <v-col>
          <ClaimFormCrawford
            v-bind.sync="form"
            @addHealthInsuranceForm="addHealthInsuranceSubForm"
            @deleteHealthInsuranceForm="deleteHealthInsuranceSubForm"
            @claimsInfoDisclaimerClick="showClaimsInfoDisclaimer"
            @fieldInput="handleFormFieldInput"
            :formParams="formParams"
            ref="form"
            showAllLabels
          ></ClaimFormCrawford>
        </v-col>
      </v-row>
      <v-row class="fnol-submit-view__footer-wrapper my-4 mt-md-6 mb-md-8" no-gutters>
        <v-col cols="12" class="fnol-submit-view__footer">
          <div class="d-flex flex-column flex-md-row justify-md-start">
            <v-btn @click="submitForm" :disabled="submitBtnDisabled">
              Submit
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <ClaimsTermsDialog @agree="handleClaimsInfoAgree" v-model="claimsInfoDisclaimerDialogShown" />
    <PhDialogCustom
      :value="showDialogOnSubmit"
      @download="handleDownloadFnol"
      @close="handleCloseDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { loadingState, scrollUtils, responseDialogs, claimForms } from '@/mixins';
import ClaimFormCrawford from '@/components/forms/ClaimFormCrawford';
import ClaimsTermsDialog from '@/components/modals/ClaimsTermsDialog';
import PhDialogCustom from '@/components/modals/PhDialogCustom';
import Preloader from '@/components/Preloader';
import Logo from '@/components/Logo';
import PageSubtitle from '@/components/typography/PageSubtitle';
import {
  CRAWFORD_CLAIM_FORM_FIELD_ALIASES,
  PROPERTY_OWNER_TYPES,
  INFO_SCREEN_TYPES_PARAMS,
  INFO_SCREEN_TYPES,
  THANK_YOU_TYPES,
  THANK_YOU_TYPES_PARAMS,
  LOSS_TYPES,
} from '@/utils/constants';

const LOSS_AND_POLICY_FIELD_ALIASES = CRAWFORD_CLAIM_FORM_FIELD_ALIASES.LOSS_AND_POLICY;
// const HEALTH_FIELD_ALIASES = CRAWFORD_CLAIM_FORM_FIELD_ALIASES.HEALTH;
const RISK_LOCATION_FIELD_ALIASES = CRAWFORD_CLAIM_FORM_FIELD_ALIASES.RISK_LOCATION;
const PROPERTY_FIELD_ALIASES = CRAWFORD_CLAIM_FORM_FIELD_ALIASES.PROPERTY;
const INJURED_FIELD_ALIASES = CRAWFORD_CLAIM_FORM_FIELD_ALIASES.INJURED;
const CALLER_FIELD_ALIASES = CRAWFORD_CLAIM_FORM_FIELD_ALIASES.CALLER;
const DISCLAIMERS_FIELD_ALIASES = CRAWFORD_CLAIM_FORM_FIELD_ALIASES.DISCLAIMERS;
const FORM_LOADING_STATE_NAMESPACE = 'FNOLForm';

export default {
  name: 'FNOLSubmitCrowford',
  mixins: [
    loadingState,
    responseDialogs,
    claimForms,
    scrollUtils({
      yOffset: 20,
    }),
  ],
  props: {
    pageTitle: {
      type: String,
    },
    organizationPageParams: {
      type: Object,
      required: true,
    },
  },
  components: {
    ClaimFormCrawford,
    ClaimsTermsDialog,
    Preloader,
    Logo,
    PageSubtitle,
    PhDialogCustom,
  },
  data() {
    return {
      claimsInfoDisclaimerDialogShown: false,
      showDialogOnSubmit: false,
      form: {
        // loss and policy info
        [LOSS_AND_POLICY_FIELD_ALIASES.POLICY_NUMBER]: null,
        [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_TYPE]: this.organizationPageParams.formIsInjuryOnly
          ? LOSS_TYPES.INJURY
          : '',
        [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_STATE]: '',
        [LOSS_AND_POLICY_FIELD_ALIASES.POLICY_STATE]: this.organizationPageParams.address.state,
        [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_DATE]: '',
        [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_TIME]: '',
        [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_DESCRIPTION]: '',
        [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_NAME]: '',
        [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_EVENT_TYPE]: '',
        [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_EVENT_NAME_AND_TYPE]: '',
        [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_LINE1]: '',
        [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_LINE2]: '',
        [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_CITY]: '',
        [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_STATE]: '',
        [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_ZIP]: '',
        [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_COUNTRY]: '',
        [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_IS_ON_PREMISSES]: null,
        // [LOSS_AND_POLICY_FIELD_ALIASES.GL_CAUSE_CODE]: '',
        // [LOSS_AND_POLICY_FIELD_ALIASES.GL_DESCRIPTION_CODE]: '',
        // health insurance info
        healthInsuranceForms: {},
        // risk location info
        [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_NAME]: '',
        [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_LINE1]: '',
        [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_LINE2]: '',
        [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_CITY]: '',
        [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_STATE]: '',
        [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_ZIP]: '',
        [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_COUNTRY]: '',
        [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_WORK_PHONE]: '',
        // property info
        [PROPERTY_FIELD_ALIASES.PROPERTY_DESCRIPTION]: '',
        [PROPERTY_FIELD_ALIASES.PROPERTY_DAMAGE_DESCRIPTION]: '',
        [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_TYPE]: PROPERTY_OWNER_TYPES.COMPANY,
        [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_COMPANY_NAME]: '',
        [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_PERSON_FIRST_NAME]: '',
        [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_PERSON_LAST_NAME]: '',
        [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_LINE1]: '',
        [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_LINE2]: '',
        [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_CITY]: '',
        [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_STATE]: '',
        [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_ZIP]: '',
        [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_COUNTRY]: '',
        [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_WORK_PHONE]: '',
        [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_HOME_PHONE]: '',
        // injured info
        [INJURED_FIELD_ALIASES.INJURED_FIRST_NAME]: '',
        [INJURED_FIELD_ALIASES.INJURED_LAST_NAME]: '',
        [INJURED_FIELD_ALIASES.INJURED_ADDRESS_LINE1]: '',
        [INJURED_FIELD_ALIASES.INJURED_ADDRESS_LINE2]: '',
        [INJURED_FIELD_ALIASES.INJURED_ADDRESS_CITY]: '',
        [INJURED_FIELD_ALIASES.INJURED_ADDRESS_STATE]: '',
        [INJURED_FIELD_ALIASES.INJURED_ADDRESS_ZIP]: '',
        [INJURED_FIELD_ALIASES.INJURED_ADDRESS_COUNTRY]: '',
        [INJURED_FIELD_ALIASES.INJURED_DOB]: null,
        [INJURED_FIELD_ALIASES.INJURED_GENDER]: null,
        [INJURED_FIELD_ALIASES.INJURED_BODY_PART_CODE]: null,
        [INJURED_FIELD_ALIASES.INJURED_INJURY_CODE]: null,
        [INJURED_FIELD_ALIASES.INJURED_INJURY_DESCRIPTION]: '',
        [INJURED_FIELD_ALIASES.INJURED_INJURED_DOING]: '',
        [INJURED_FIELD_ALIASES.INJURED_TAKEN_TO_HOSPITAL_BY_AMBULANCE]: null,
        [INJURED_FIELD_ALIASES.INJURED_INJURY_RESULT_IN_DEATH]: null,
        [INJURED_FIELD_ALIASES.INJURED_MEMBER_ID]: '',
        [INJURED_FIELD_ALIASES.INJURED_NAME_OF_TEAM]: '',
        [INJURED_FIELD_ALIASES.INJURED_STATE_ASSOCIATION]: this.organizationPageParams
          .organizationName,
        [INJURED_FIELD_ALIASES.INJURED_NAME_OF_LEAGUE]: '',
        [INJURED_FIELD_ALIASES.CLAIMANT_CURRENTLY_ENROLLED_ACCIDENT_PLAN]: true,
        // caller info
        [CALLER_FIELD_ALIASES.CALLER_FIRST_NAME]: '',
        [CALLER_FIELD_ALIASES.CALLER_LAST_NAME]: '',
        [CALLER_FIELD_ALIASES.CALLER_EMAIL]: '',
        [CALLER_FIELD_ALIASES.CALLER_WORK_PHONE]: '',
        [CALLER_FIELD_ALIASES.CALLER_TYPE]: '',
        // disclaimers
        [DISCLAIMERS_FIELD_ALIASES.READ_AND_AGREE_TO_CLAIMS_INFO]: true,
        // disclaimers
        [DISCLAIMERS_FIELD_ALIASES.INCIDENT_VICTIM_SIGNATURE]: '',
      },
      // form: {
      //   // loss and policy info
      //   [LOSS_AND_POLICY_FIELD_ALIASES.POLICY_NUMBER]: 'PN0000000000001',
      //   [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_TYPE]: LOSS_TYPES.BOTH,
      //   [LOSS_AND_POLICY_FIELD_ALIASES.POLICY_STATE]: 'CA',
      //   [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_DATE]: '2020-05-10',
      //   [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_TIME]: '12:30',
      //   [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_DESCRIPTION]: 'Loss Description',
      //   [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_NAME]: 'Loss Location Name',
      //   [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_EVENT_TYPE]: '',
      //   [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_EVENT_NAME_AND_TYPE]: 'Name and Type of Event',
      //   [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_LINE1]: 'Loss address 1',
      //   [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_LINE2]: 'Loss address 2',
      //   [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_CITY]: 'Lcity',
      //   [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_STATE]: 'MN',
      //   [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_ZIP]: '12345',
      //   [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_COUNTRY]: 'USA',
      //   [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_IS_ON_PREMISSES]: true,
      //   // [LOSS_AND_POLICY_FIELD_ALIASES.GL_CAUSE_CODE]: '001',
      //   // [LOSS_AND_POLICY_FIELD_ALIASES.GL_DESCRIPTION_CODE]: '110',
      //   // health insurance info
      //   healthInsuranceForms: {},
      //   // risk location info
      //   [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_NAME]: 'Risk location name',
      //   [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_LINE1]: 'Risk address 1',
      //   [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_LINE2]: 'Risk address 2',
      //   [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_CITY]: 'Rcity',
      //   [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_STATE]: 'AL',
      //   [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_ZIP]: '12354',
      //   [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_COUNTRY]: 'USA',
      //   [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_WORK_PHONE]: '1234567890',
      //   // property info
      //   [PROPERTY_FIELD_ALIASES.PROPERTY_DESCRIPTION]: 'Property description',
      //   [PROPERTY_FIELD_ALIASES.PROPERTY_DAMAGE_DESCRIPTION]: 'Damage description',
      //   [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_TYPE]: PROPERTY_OWNER_TYPES.COMPANY,
      //   [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_COMPANY_NAME]: 'Company name',
      //   [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_PERSON_FIRST_NAME]: '',
      //   [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_PERSON_LAST_NAME]: '',
      //   [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_LINE1]: 'Property address 1',
      //   [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_LINE2]: 'Property address 2',
      //   [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_CITY]: 'Pcity',
      //   [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_STATE]: 'AK',
      //   [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_ZIP]: '13245',
      //   [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_COUNTRY]: 'USA',
      //   [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_WORK_PHONE]: '1324567890',
      //   [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_HOME_PHONE]: '1243567890',
      //   // injured info
      //   [INJURED_FIELD_ALIASES.INJURED_FIRST_NAME]: 'Ifname',
      //   [INJURED_FIELD_ALIASES.INJURED_LAST_NAME]: 'Ilname',
      //   [INJURED_FIELD_ALIASES.INJURED_ADDRESS_LINE1]: 'Injured address 1',
      //   [INJURED_FIELD_ALIASES.INJURED_ADDRESS_LINE2]: 'Injured address 2',
      //   [INJURED_FIELD_ALIASES.INJURED_ADDRESS_CITY]: 'Icity',
      //   [INJURED_FIELD_ALIASES.INJURED_ADDRESS_STATE]: 'AZ',
      //   [INJURED_FIELD_ALIASES.INJURED_ADDRESS_ZIP]: '14352',
      //   [INJURED_FIELD_ALIASES.INJURED_ADDRESS_COUNTRY]: 'USA',
      //   [INJURED_FIELD_ALIASES.INJURED_DOB]: '1987-01-02',
      //   [INJURED_FIELD_ALIASES.INJURED_GENDER]: 'MALE',
      //   [INJURED_FIELD_ALIASES.INJURED_BODY_PART_CODE]: 'FACE',
      //   [INJURED_FIELD_ALIASES.INJURED_INJURY_CODE]: 'RASH',
      //   [INJURED_FIELD_ALIASES.INJURED_INJURY_DESCRIPTION]: 'Injury description',
      //   [INJURED_FIELD_ALIASES.INJURED_INJURED_DOING]: 'Nothing',
      //   [INJURED_FIELD_ALIASES.INJURED_TAKEN_TO_HOSPITAL_BY_AMBULANCE]: true,
      //   [INJURED_FIELD_ALIASES.INJURED_INJURY_RESULT_IN_DEATH]: false,
      //   [INJURED_FIELD_ALIASES.INJURED_MEMBER_ID]: 'MemberId',
      //   [INJURED_FIELD_ALIASES.INJURED_NAME_OF_TEAM]: 'TeamName',
      //   [INJURED_FIELD_ALIASES.INJURED_STATE_ASSOCIATION]: 'AssociationName',
      //   [INJURED_FIELD_ALIASES.INJURED_NAME_OF_LEAGUE]: 'LeagueName',
      //   [INJURED_FIELD_ALIASES.CLAIMANT_CURRENTLY_ENROLLED_ACCIDENT_PLAN]: false,
      //   // caller info
      //   [CALLER_FIELD_ALIASES.CALLER_FIRST_NAME]: 'Cfname',
      //   [CALLER_FIELD_ALIASES.CALLER_LAST_NAME]: 'Clname',
      //   [CALLER_FIELD_ALIASES.CALLER_EMAIL]: 'emil.ivanov+claimant@mentormate.com',
      //   [CALLER_FIELD_ALIASES.CALLER_WORK_PHONE]: '1234568790',
      //   [CALLER_FIELD_ALIASES.CALLER_TYPE]: 'INSURED_CLIENT',
      //   // disclaimers
      //   [DISCLAIMERS_FIELD_ALIASES.INCIDENT_VICTIM_SIGNATURE]: 'Signature',
      // },
    };
  },
  watch: {
    [`form.${INJURED_FIELD_ALIASES.CLAIMANT_CURRENTLY_ENROLLED_ACCIDENT_PLAN}`]: {
      handler(newVal) {
        if (newVal) {
          this.initHealthInsuranceSubForms();
        } else {
          this.resetHealthInsuranceSubForms();
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('claims', ['submittedFnolId']),
    formParams() {
      return {
        organizationName: this.organizationPageParams.organizationName,
        form: this.organizationPageParams.form,
        formIsInjuryOnly: this.organizationPageParams.formIsInjuryOnly,
      };
    },
    submitBtnDisabled() {
      return (
        this.loadingState[FORM_LOADING_STATE_NAMESPACE] ||
        !this.form[DISCLAIMERS_FIELD_ALIASES.INCIDENT_VICTIM_SIGNATURE]
      );
    },
  },
  methods: {
    ...mapActions('claims', ['submitCrawfordFNOLCreateForm', 'generateClaimPdf']),
    ...mapActions('captcha', ['executeAction']),
    handleDownloadFnol() {
      this.generateClaimPdf(this.submittedFnolId);
      this.showDialogOnSubmit = false;
    },
    handleCloseDialog() {
      this.showDialogOnSubmit = false;
      this.$router.push({
        name: 'infoScreen',
        params: {
          INFO_SCREEN_TYPE: INFO_SCREEN_TYPES_PARAMS[INFO_SCREEN_TYPES.THANK_YOU],
          TYPE: THANK_YOU_TYPES_PARAMS[THANK_YOU_TYPES.FNOL_SUBMIT],
          CLAIMANT_NAME: `${this.form[INJURED_FIELD_ALIASES.INJURED_FIRST_NAME]} ${
            this.form[INJURED_FIELD_ALIASES.INJURED_LAST_NAME]
          }`,
        },
      });
      this.resetForm();
    },
    handleClaimsInfoAgree() {
      this.form[DISCLAIMERS_FIELD_ALIASES.READ_AND_AGREE_TO_CLAIMS_INFO] = true;
      this.hideClaimsInfoDisclaimer();
    },
    showClaimsInfoDisclaimer() {
      this.claimsInfoDisclaimerDialogShown = true;
    },
    hideClaimsInfoDisclaimer() {
      this.claimsInfoDisclaimerDialogShown = false;
    },
    resetForm() {
      if (this.$refs.form && typeof this.$refs.form.reset === 'function') {
        this.$refs.form.reset();
      }
    },
    async submitForm() {
      if (this.$refs.form) {
        const canSubmit = this.$refs.form.submit();
        if (canSubmit) {
          try {
            this.setLoadingState(FORM_LOADING_STATE_NAMESPACE, true);
            const payload = {
              ...this.form,
              sportsUnionId: this.organizationPageParams.organizationId,
            };
            // TODO: what is this line ?
            // await this.executeAction('submitFNOLCrawford');
            const success = await this.submitCrawfordFNOLCreateForm(payload);
            this.setLoadingState(FORM_LOADING_STATE_NAMESPACE, false);
            if (success) {
              this.showDialogOnSubmit = true;
            } else {
              throw new Error('There was en error while submitting your FNOL');
            }
            return success;
          } catch (error) {
            this.setLoadingState(FORM_LOADING_STATE_NAMESPACE, false);
            this.$dialog.error({ error });
            return error;
          }
        } else {
          this.scrollToFirstError();
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.fnol-submit-view {
  background-color: $background-lightest;
}
.fnol-submit-view__container {
  max-width: 780px;
}
.fnol-submit-view__header {
  padding-top: 90px;
}
.fnol-submit-view__header-logo-wrapper {
  display: flex;
  margin-bottom: 40px;
  max-width: 90px;
}
.fnol-submit-view__header-text-wrapper {
  margin-top: 12px;
}
.fnol-submit-view__header-text {
  color: $text-semi-dark;
  font-size: 14px;
  line-height: 1.14;
}
</style>
