<template>
  <div class="fnol-submit-view fill-height">
    <Preloader :value="organizationPageParams.loading" fullscreen />
    <v-container
      v-if="organizationPageParams.ready"
      class="fnol-submit-view__container"
      fill-height
      align-start
      align-content-start
    >
      <LangToggler />
      <v-row class="fnol-submit-view__header" no-gutters>
        <v-col>
          <div class="fnol-submit-view__header-logo-wrapper">
            <Logo :src="organizationPageParams.logoUrl || '/img/logo/usasa-logo.png'" large />
          </div>
          <div class="fnol-submit-view__header-title-wrapper">
            <PageSubtitle>{{ pageTitle }} {{ $t('messages.usasaTitle') }}</PageSubtitle>
          </div>
        </v-col>
      </v-row>
      <v-row class="fnol-submit-view__body mt-4 mt-md-10" no-gutters>
        <v-col>
          <ClaimFormNagha
            v-bind.sync="form"
            @addHealthInsuranceForm="addHealthInsuranceSubForm"
            @deleteHealthInsuranceForm="deleteHealthInsuranceSubForm"
            @claimsInfoDisclaimerClick="showClaimsInfoDisclaimer"
            @fieldInput="handleFormFieldInput"
            :formParams="formParams"
            ref="form"
          ></ClaimFormNagha>
        </v-col>
      </v-row>
      <v-row class="fnol-submit-view__footer-wrapper my-4 mt-md-6 mb-md-8" no-gutters>
        <v-col cols="12" class="fnol-submit-view__footer">
          <div class="d-flex flex-column flex-md-row justify-md-end">
            <v-btn @click="submitForm" :disabled="submitBtnDisabled">
              {{ $t('ui.submit') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <ClaimsTermsDialog @agree="handleClaimsInfoAgree" v-model="claimsInfoDisclaimerDialogShown" />
    <PhDialogCustom
      :value="showDialogOnSubmit"
      @download="handleDownloadFnol"
      @close="handleCloseDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { fileUpload, scrollUtils, responseDialogs, claimForms } from '@/mixins';
import ClaimFormNagha from '@/components/forms/ClaimFormNagha';
import ClaimsTermsDialog from '@/components/modals/ClaimsTermsDialog';
import LangToggler from '@/components/LangToggler';
import Preloader from '@/components/Preloader';
import Logo from '@/components/Logo';
import PageSubtitle from '@/components/typography/PageSubtitle';
import PhDialogCustom from '@/components/modals/PhDialogCustom';
import {
  NAGHA_CLAIM_FORM_FIELD_ALIASES,
  INFO_SCREEN_TYPES_PARAMS,
  INFO_SCREEN_TYPES,
  THANK_YOU_TYPES,
  THANK_YOU_TYPES_PARAMS,
  INJURED_PERSON_TYPES,
} from '@/utils/constants';

const CLAIMANT_FIELD_ALIASES = NAGHA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT;
const HEALTH_FIELD_ALIASES = NAGHA_CLAIM_FORM_FIELD_ALIASES.HEALTH;
const DISCLAIMERS_FIELD_ALIASES = NAGHA_CLAIM_FORM_FIELD_ALIASES.DISCLAIMERS;
const INCIDENT_FIELD_ALIASES = NAGHA_CLAIM_FORM_FIELD_ALIASES.INCIDENT;

export default {
  name: 'FNOLSubmit',
  mixins: [
    fileUpload,
    responseDialogs,
    claimForms,
    scrollUtils({
      yOffset: 20,
    }),
  ],
  props: {
    pageTitle: {
      type: String,
    },
    organizationPageParams: {
      type: Object,
      required: true,
    },
  },
  components: {
    LangToggler,
    ClaimFormNagha,
    ClaimsTermsDialog,
    Preloader,
    Logo,
    PageSubtitle,
    PhDialogCustom,
  },
  data() {
    return {
      claimsInfoDisclaimerDialogShown: false,
      showDialogOnSubmit: false,
      form: {
        // claimant info
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_FIRST_NAME]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_LAST_NAME]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_DOB]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_GENDER]: '',
        // part two
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STREET]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_ADDITIONAL]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_CITY]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STATE]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_ZIP]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_COUNTRY]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_EMAIL]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_HOME_PHONE]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_WORK_PHONE]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_STATUS]: 'SINGLE',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_INJURED_PERSON_TYPE]: INJURED_PERSON_TYPES.PARTICIPANT,
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_MEDICAID_BENEFICIARY]: false,
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_SS_NOR_HID_NUMBER]: '',
        // coach info
        // disclaimers
        [DISCLAIMERS_FIELD_ALIASES.READ_AND_AGREE_TO_CLAIMS_INFO]: false,
        // [COACH_FIELD_ALIASES.COACH_EMAIL]: '',
        // [COACH_FIELD_ALIASES.COACH_FIRST_NAME]: '',
        // [COACH_FIELD_ALIASES.COACH_LAST_NAME]: '',
        // incident details
        [INCIDENT_FIELD_ALIASES.INCIDENT_DATE]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_TIME]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_LOCATION]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_BODY_PART_INJURED]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_BODY_SIDE_INJURED]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_TYPE]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_ACCIDENT_OCCUR]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_OCCURED_AT]: '',
        // INCIDENT_INJURY_OCCURED_AT: 'incidentInjuryOccuredAt',
        // INCIDENT_INJURY_OCCURED_AT_OTHER: 'incidentInjuryOccuredAtOther',
        [INCIDENT_FIELD_ALIASES.INCIDENT_EVENT_NAME_AND_TYPE]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_DESCRIPTION]: '',
        [INCIDENT_FIELD_ALIASES.INCEDENT_NAME_OF_TEAM]: '',
        [INCIDENT_FIELD_ALIASES.INCEDENT_CLAIMANT_CHECKBOX1]: false,
        [INCIDENT_FIELD_ALIASES.INCEDENT_CLAIMANT_CHECKBOX2]: false,
        [INCIDENT_FIELD_ALIASES.INCEDENT_CLAIMANT_SIGNATURE]: '',
        [INCIDENT_FIELD_ALIASES.INCEDENT_NAME_OF_LEAGUE]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_RESULT_IN_DEATH]: false,
        [INCIDENT_FIELD_ALIASES.INCIDENT_NOTE]: '',
        // health insurance info
        healthInsuranceForms: {},
        [HEALTH_FIELD_ALIASES.HEALTH_CLAIMANT_HAS_HEALTHCARE]: false,
      },
    };
  },
  watch: {
    [`form.${HEALTH_FIELD_ALIASES.HEALTH_CLAIMANT_HAS_HEALTHCARE}`]: {
      handler(newVal) {
        if (newVal) {
          this.initHealthInsuranceSubForms();
        } else {
          this.resetHealthInsuranceSubForms();
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('claims', ['submittedFnolId']),
    formParams() {
      return {
        loadingFiles: this.fileUpload.loading,
        organizationName: this.organizationPageParams.organizationName,
        stateAdmins: this.organizationPageParams.stateAdmins,
        form: this.organizationPageParams.form,
      };
    },
    submitBtnDisabled() {
      return false;
    },
  },
  methods: {
    ...mapActions('claims', ['submitNaghaFNOLCreateForm', 'generateClaimPdf']),
    ...mapActions('captcha', ['executeAction']),
    handleDownloadFnol() {
      this.generateClaimPdf(this.submittedFnolId);
      this.showDialogOnSubmit = false;
    },
    handleCloseDialog() {
      this.showDialogOnSubmit = false;
      this.$router.push({
        name: 'infoScreen',
        params: {
          INFO_SCREEN_TYPE: INFO_SCREEN_TYPES_PARAMS[INFO_SCREEN_TYPES.THANK_YOU],
          TYPE: THANK_YOU_TYPES_PARAMS[THANK_YOU_TYPES.FNOL_SUBMIT],
          CLAIMANT_NAME: `${this.form[CLAIMANT_FIELD_ALIASES.CLAIMANT_FIRST_NAME]} ${
            this.form[CLAIMANT_FIELD_ALIASES.CLAIMANT_LAST_NAME]
          }`,
        },
      });
      this.resetForm();
    },

    handleClaimsInfoAgree() {
      this.form[DISCLAIMERS_FIELD_ALIASES.READ_AND_AGREE_TO_CLAIMS_INFO] = true;
      this.hideClaimsInfoDisclaimer();
    },
    showClaimsInfoDisclaimer() {
      this.claimsInfoDisclaimerDialogShown = true;
    },
    hideClaimsInfoDisclaimer() {
      this.claimsInfoDisclaimerDialogShown = false;
    },
    resetForm() {
      if (this.$refs.form && typeof this.$refs.form.reset === 'function') {
        this.$refs.form.reset();
      }
    },
    async submitForm() {
      if (this.$refs.form) {
        const canSubmit = this.$refs.form.submit();
        if (canSubmit) {
          try {
            const payload = {
              ...this.form,
              sportsUnionId: this.organizationPageParams.organizationId,
            };
            await this.executeAction('submitFNOLFysa');
            const success = await this.submitNaghaFNOLCreateForm(payload);
            if (success) {
              this.showDialogOnSubmit = true;
            } else {
              throw new Error('There was en error while submitting your FNOL');
            }
            return success;
          } catch (error) {
            this.$dialog.error({ error });
            return error;
          }
        } else {
          this.scrollToFirstError();
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.fnol-submit-view {
  background-color: $background-lightest;
}
.fnol-submit-view__container {
  max-width: 780px;
}
.fnol-submit-view__header {
  padding-top: 90px;
}
.fnol-submit-view__header-logo-wrapper {
  display: flex;
  margin-bottom: 40px;
  max-width: 90px;
}
.fnol-submit-view__header-text-wrapper {
  margin-top: 12px;
}
.fnol-submit-view__header-text {
  color: $text-semi-dark;
  font-size: 14px;
  line-height: 1.14;
}
</style>
