<template>
  <div class="fnol-submit-view fill-height">
    <Preloader :value="organizationPageParams.loading" fullscreen />
    <v-container
      v-if="organizationPageParams.ready"
      class="fnol-submit-view__container"
      fill-height
      align-start
      align-content-start
    >
      <v-row class="fnol-submit-view__header" no-gutters>
        <v-col>
          <div class="fnol-submit-view__header-logo-wrapper">
            <Logo :src="organizationPageParams.logoUrl" large />
          </div>
          <div v-if="pageTitle" class="fnol-submit-view__header-title-wrapper">
            <PageSubtitle>{{ pageTitle }}</PageSubtitle>
          </div>
          <div
            v-if="organizationPageParams.complianceWarning"
            class="fnol-submit-view__header-text-wrapper"
          >
            <p class="fnol-submit-view__header-text my-0">
              {{ organizationPageParams.complianceWarning }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row class="fnol-submit-view__body mt-4 mt-md-10" no-gutters>
        <v-col>
          <ClaimForm
            v-bind.sync="form"
            @fieldInput="handleClaimantFilesAttachement"
            @claimsInfoDisclaimerClick="showClaimsInfoDisclaimer"
            :formParams="formParams"
            ref="form"
          ></ClaimForm>
        </v-col>
      </v-row>
      <v-row class="fnol-submit-view__footer-wrapper my-4 mt-md-6 mb-md-8" no-gutters>
        <v-col cols="12" class="fnol-submit-view__footer">
          <div class="d-flex flex-column flex-md-row justify-md-end">
            <v-btn @click="submitForm" :disabled="submitBtnDisabled">
              Submit
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <ClaimsTermsDialog @agree="handleClaimsInfoAgree" v-model="claimsInfoDisclaimerDialogShown" />
    <PhDialogCustom
      :value="showDialogOnSubmit"
      @download="handleDownloadFnol"
      @close="handleCloseDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { fileUpload, loadingState, scrollUtils, responseDialogs } from '@/mixins';
import ClaimForm from '@/components/forms/ClaimForm';
import ClaimsTermsDialog from '@/components/modals/ClaimsTermsDialog';
import PhDialogCustom from '@/components/modals/PhDialogCustom';
import Preloader from '@/components/Preloader';
import Logo from '@/components/Logo';
import PageSubtitle from '@/components/typography/PageSubtitle';
import {
  CLAIM_FORM_FIELD_ALIASES,
  INFO_SCREEN_TYPES_PARAMS,
  INFO_SCREEN_TYPES,
  THANK_YOU_TYPES,
  THANK_YOU_TYPES_PARAMS,
} from '@/utils/constants';

const CLAIMANT_FIELD_ALIASES = CLAIM_FORM_FIELD_ALIASES.CLAIMANT;
const COACH_FIELD_ALIASES = CLAIM_FORM_FIELD_ALIASES.COACH;
const DISCLAIMERS_FIELD_ALIASES = CLAIM_FORM_FIELD_ALIASES.DISCLAIMERS;
const INCIDENT_FIELD_ALIASES = CLAIM_FORM_FIELD_ALIASES.INCIDENT;
const INSURANCE_FIELD_ALIASES = CLAIM_FORM_FIELD_ALIASES.INSURANCE;
const FORM_LOADING_STATE_NAMESPACE = 'FNOLForm';

export default {
  name: 'FNOLSubmit',
  mixins: [
    fileUpload,
    loadingState,
    responseDialogs,
    scrollUtils({
      yOffset: 20,
    }),
  ],
  props: {
    pageTitle: {
      type: String,
    },
    organizationPageParams: {
      type: Object,
      required: true,
    },
  },
  components: {
    ClaimForm,
    ClaimsTermsDialog,
    Preloader,
    Logo,
    PageSubtitle,
    PhDialogCustom,
  },
  data() {
    return {
      claimsInfoDisclaimerDialogShown: false,
      showDialogOnSubmit: false,
      form: {
        // claimant info
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_CITY]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_COUNTRY]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STATE]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STREET]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_ZIP]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_DOB]: null,
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_EMAIL]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_MEMBER_ID]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_GENDER]: null,
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_FIRST_NAME]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_LAST_NAME]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_MIDDLE_INITIAL]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_FATHERS_FULL_NAME]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_MOTHERS_FULL_NAME]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ROLE_IN_ORG]: null,
        // coach info
        [COACH_FIELD_ALIASES.COACH_INJURED_TREATED_BY_OFFICIALS]: false,
        [COACH_FIELD_ALIASES.COACH_INJURED_CLUB_NAME]: '',
        [COACH_FIELD_ALIASES.COACH_EMAIL]: '',
        [COACH_FIELD_ALIASES.COACH_FIRST_NAME]: '',
        [COACH_FIELD_ALIASES.COACH_INCIDENT_TERRITORY]: '',
        [COACH_FIELD_ALIASES.COACH_LAST_NAME]: '',
        [COACH_FIELD_ALIASES.COACH_PHONE]: '',
        [COACH_FIELD_ALIASES.COACH_SIGNATURE]: '',
        // disclaimers
        [DISCLAIMERS_FIELD_ALIASES.READ_AND_AGREE_TO_CLAIMS_INFO]: false,
        // incident details
        [INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_TPA_PROCESSING]: false,
        [INCIDENT_FIELD_ALIASES.INCIDENT_BODY_PART_INJURED]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_BODY_SIDE_INJURED]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_CONTACT]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_CONTACT_TYPE]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_DATE]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_DESCRIPTION]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_EVENT_NAME]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_EVENT_TYPE]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_TYPE]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_LOCATION]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_MEMBERSHIP_TYPE]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_MEMBERSHIP_OTHER_TYPE]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_ONSITE_CARE_RECEIVED]: false,
        [INCIDENT_FIELD_ALIASES.INCIDENT_OTHER_CONTACT_TYPE]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_PLAYING_SURFACE]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_SEVERITY]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_TAKEN_TO_HOSPITAL_BY_AMBULANCE]: false,
        [INCIDENT_FIELD_ALIASES.INCIDENT_TIME]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_VICTIM_SIGNATURE]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_VICTIM_FILES]: [],
        [INCIDENT_FIELD_ALIASES.INCIDENT_NOTE]: '',
        // insurance info
        [INSURANCE_FIELD_ALIASES.INSURANCE_COMPANY_NAME]: '',
        [INSURANCE_FIELD_ALIASES.INSURANCE_POLICYHOLDER_NAME]: '',
        [INSURANCE_FIELD_ALIASES.INSURANCE_POLICY_NUMBER]: '',
        [INSURANCE_FIELD_ALIASES.INSURANCE_GROUP_NUMBER]: '',
      },
    };
  },
  computed: {
    ...mapGetters('claims', ['submittedFnolId']),
    formParams() {
      return {
        loadingFiles: this.fileUpload.loading,
        organizationName: this.organizationPageParams.organizationName,
        form: this.organizationPageParams.form,
      };
    },
    submitBtnDisabled() {
      return (
        this.loadingState[FORM_LOADING_STATE_NAMESPACE] ||
        !this.form[DISCLAIMERS_FIELD_ALIASES.READ_AND_AGREE_TO_CLAIMS_INFO] ||
        !this.form[INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_TPA_PROCESSING] ||
        this.form[INCIDENT_FIELD_ALIASES.INCIDENT_VICTIM_FILES].length !== this.uploadIds.length
      );
    },
  },
  methods: {
    ...mapActions('claims', ['submitFNOLCreateForm', 'generateClaimPdf']),
    ...mapActions('captcha', ['executeAction']),
    handleDownloadFnol() {
      this.generateClaimPdf(this.submittedFnolId);
      this.showDialogOnSubmit = false;
    },
    handleCloseDialog() {
      this.showDialogOnSubmit = false;
      this.$router.push({
        name: 'infoScreen',
        params: {
          INFO_SCREEN_TYPE: INFO_SCREEN_TYPES_PARAMS[INFO_SCREEN_TYPES.THANK_YOU],
          TYPE: THANK_YOU_TYPES_PARAMS[THANK_YOU_TYPES.FNOL_SUBMIT],
          CLAIMANT_NAME: `${this.form[CLAIMANT_FIELD_ALIASES.CLAIMANT_FIRST_NAME]} ${
            this.form[CLAIMANT_FIELD_ALIASES.CLAIMANT_LAST_NAME]
          }`,
        },
      });
      this.resetForm();
    },
    async handleClaimantFilesAttachement({ field, value }) {
      if (field === INCIDENT_FIELD_ALIASES.INCIDENT_VICTIM_FILES) {
        try {
          const uploads = await this.uploadMultipleFiles(value);
          return !!uploads;
        } catch (error) {
          console.error(error);
          this.$dialog.error({ error });
          return false;
        }
      }
      return false;
    },
    handleClaimsInfoAgree() {
      this.form[DISCLAIMERS_FIELD_ALIASES.READ_AND_AGREE_TO_CLAIMS_INFO] = true;
      this.hideClaimsInfoDisclaimer();
    },
    showClaimsInfoDisclaimer() {
      this.claimsInfoDisclaimerDialogShown = true;
    },
    hideClaimsInfoDisclaimer() {
      this.claimsInfoDisclaimerDialogShown = false;
    },
    resetForm() {
      if (this.$refs.form && typeof this.$refs.form.reset === 'function') {
        this.$refs.form.reset();
      }
    },
    async submitForm() {
      if (this.$refs.form) {
        const canSubmit = this.$refs.form.submit();
        if (canSubmit) {
          try {
            this.setLoadingState(FORM_LOADING_STATE_NAMESPACE, true);
            const payload = {
              ...this.form,
              sportsUnionId: this.organizationPageParams.organizationId,
            };
            if (this.uploadIds instanceof Array && this.uploadIds.length) {
              payload.filesIds = this.uploadIds;
            }
            await this.executeAction('submitFNOL');
            const success = await this.submitFNOLCreateForm(payload);
            this.setLoadingState(FORM_LOADING_STATE_NAMESPACE, false);
            if (success) {
              this.showDialogOnSubmit = true;
            } else {
              throw new Error('There was en error while submitting your FNOL');
            }
            return success;
          } catch (error) {
            this.setLoadingState(FORM_LOADING_STATE_NAMESPACE, false);
            this.$dialog.error({ error });
            return error;
          }
        } else {
          this.scrollToFirstError();
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.fnol-submit-view {
  background-color: $background-lightest;
}
.fnol-submit-view__container {
  max-width: 780px;
}
.fnol-submit-view__header {
  padding-top: 90px;
}
.fnol-submit-view__header-logo-wrapper {
  display: flex;
  margin-bottom: 40px;
  max-width: 90px;
}
.fnol-submit-view__header-text-wrapper {
  margin-top: 12px;
}
.fnol-submit-view__header-text {
  color: $text-semi-dark;
  font-size: 14px;
  line-height: 1.14;
}
</style>
