<template>
  <v-container pa-0 fluid class="olympicFormClaimantInfoSection">
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_FIRST_NAME, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_FIRST_NAME]"
          :rules="['required']"
          label="First name"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_LAST_NAME, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_LAST_NAME]"
          :rules="['required']"
          label="Last name"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <PhDatepicker
          fullWidth
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_DOB, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_DOB]"
          :hide-details="false"
          placeholder="Date of birth"
          required
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_GENDER, arguments[0])"
          :items="selectOptions.genders"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_GENDER]"
          :rules="['required']"
          placeholder="Gender"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_SSN, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_SSN]"
          :rules="['SSN']"
          v-mask="'XXX-XX-XXXX'"
          placeholder="XXX-XX-XXXX"
          label="SSN"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_EMAIL, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_EMAIL]"
          :rules="['required', 'email']"
          type="email"
          label="Email"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STREET, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STREET]"
          label="Street"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_CITY, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_CITY]"
          label="City"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STATE, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STATE]"
          label="State"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_ZIP, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_ZIP]"
          label="ZIP"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <FormSection title="If minor" darkTitle>
          <v-text-field
            autocomplete="new-password"
            @input="
              handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_PARENTS_FULL_NAME, arguments[0])
            "
            :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_PARENTS_FULL_NAME]"
            label="Father’s or Mother’s Name"
          />
        </FormSection>
      </v-col>
      <v-col cols="12" md="6">
        <FormSection class="olympicFormClaimantInfoSection__emptyTitleFormSection">
          <v-text-field
            autocomplete="new-password"
            @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_PARENTS_ADDRESS, arguments[0])"
            :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_PARENTS_ADDRESS]"
            label="Father’s or Mother’s Address"
          />
        </FormSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_PARENTS_PHONE, arguments[0])"
          :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_PARENTS_PHONE]"
          :rules="['phone']"
          type="tel"
          label="Father’s or Mother’s Phone"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <FormSection title="Are you" darkTitle>
          <v-select
            @input="handleFieldInput(CLAIMANT_FIELD_ALIASES.CLAIMANT_ROLE_IN_ORG, arguments[0])"
            :items="selectOptions.olympicOrganizationRoles"
            :value="this[CLAIMANT_FIELD_ALIASES.CLAIMANT_ROLE_IN_ORG]"
            placeholder="Select role"
          />
        </FormSection>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mask } from 'vue-the-mask';
import { syncForm } from '@/mixins';
import { genders, olympicOrganizationRoles } from '@/utils/options';
import { getClaimantInfoFormProps } from '@/utils/helpers/app/claimsOlympic';
import FormSection from '@/components/FormSection';
import PhDatepicker from '@/components/pickers/PhDatepicker';

export default {
  name: 'ClaimantInfo',
  mixins: [syncForm],
  directives: { mask },
  components: {
    FormSection,
    PhDatepicker,
  },
  props: {
    ...getClaimantInfoFormProps(),
  },
  data() {
    return {
      selectOptions: {
        genders,
        olympicOrganizationRoles,
      },
    };
  },
  mounted() {},
};
</script>

<style lang="scss">
@import '~@/scss/base/index';

.olympicFormClaimantInfoSection__emptyTitleFormSection {
  &.form-section {
    .form-section__header {
      @include bp-down(md) {
        display: none;
      }
    }
    .form-section__body {
      @include bp-down(md) {
        padding-top: 0px;
      }
    }
  }
}
</style>
