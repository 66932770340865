<template>
  <v-form ref="form" class="claim-form">
    <div class="claim-form__section-wrapper">
      <FormGroup title="Injured person information:" titleTransform="uppercase">
        <ClaimantInfo v-bind="claimantInfoFormProps" @fieldInput="handleFormSectionFieldInput" />
      </FormGroup>
    </div>
    <div class="claim-form__section-wrapper">
      <FormGroup
        :title="subInfoLabel"
        titleTransform="uppercase"
        subtitle="Primary Health Insurance MUST be filed prior to this policy."
        noSubtitleBottomMargin
      >
        <InsuranceInfo v-bind="insuranceInfoFormProps" @fieldInput="handleFormSectionFieldInput" />
      </FormGroup>
    </div>
    <div class="claim-form__section-wrapper">
      <FormGroup
        title="Time, place and details of incident:"
        titleTransform="uppercase"
        subtitle="Primary Health Insurance MUST be filed prior to this policy."
      >
        <IncidentDetails
          v-bind="incidentDetailsFormProps"
          :hiddenFields="formParams.hiddenFields"
          :loadingFiles="formParams.loadingFiles"
          @fieldInput="handleFormSectionFieldInput"
        />
      </FormGroup>
    </div>
    <div class="claim-form__section-wrapper mt-0">
      <FormGroup>
        <CoachInfo v-bind="coachInfoFormProps" @fieldInput="handleFormSectionFieldInput" />
      </FormGroup>
    </div>
    <div class="claim-form__section-wrapper my-0">
      <Disclaimers
        v-bind="disclaimersFormProps"
        :hiddenFields="formParams.hiddenFields"
        @claimsInfoClick="handleClaimsInfoDisclaimerClick"
        @fieldInput="handleFormSectionFieldInput"
      />
    </div>
  </v-form>
</template>

<script>
import { syncForm, validateForm, claimFormOlympic } from '@/mixins';
import ClaimantInfo from '@/components/forms/ClaimFormOlympic/partials/ClaimantInfo';
import CoachInfo from '@/components/forms/ClaimFormOlympic/partials/CoachInfo';
import Disclaimers from '@/components/forms/ClaimFormOlympic/partials/Disclaimers';
import FormGroup from '@/components/FormGroup';
import IncidentDetails from '@/components/forms/ClaimFormOlympic/partials/IncidentDetails';
import InsuranceInfo from '@/components/forms/ClaimFormOlympic/partials/InsuranceInfo';

export default {
  name: 'OlympicClaimForm',
  mixins: [claimFormOlympic, syncForm, validateForm],
  components: {
    ClaimantInfo,
    CoachInfo,
    Disclaimers,
    FormGroup,
    IncidentDetails,
    InsuranceInfo,
  },
  computed: {
    subInfoLabel() {
      return `${
        ['FYSA', 'COLORADOMTB'].includes(this.formParams.form)
          ? this.$t('ui.claimantAddress')
          : this.$t('ui.primaryHealthInsurance')
      }:`;
    },
  },
  methods: {
    handleFormSectionFieldInput({ field, value }) {
      this.handleFieldInput(field, value);
    },
  },
};
</script>

<style lang="scss" scoped>
.claim-form__section-wrapper {
  &:nth-child(1n + 2) {
    margin-top: 45px;
  }
}
</style>
