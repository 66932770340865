<template>
  <v-container pa-0 fluid>
    <v-row>
      <v-col cols="12">
        <FormSection title="Was athlete treated by Trainer and/or Official?">
          <v-radio-group
            @change="
              handleFieldInput(COACH_FIELD_ALIASES.COACH_INJURED_TREATED_BY_OFFICIALS, arguments[0])
            "
            :value="this[COACH_FIELD_ALIASES.COACH_INJURED_TREATED_BY_OFFICIALS]"
            :rules="['requiredBoolean']"
            row
          >
            <v-radio :value="true" label="Yes"></v-radio>
            <v-radio :value="false" label="No"></v-radio>
          </v-radio-group>
        </FormSection>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { syncForm } from '@/mixins';
import { getCoachInfoFormProps } from '@/utils/helpers/app/claimsOlympic';
import FormSection from '@/components/FormSection';

export default {
  name: 'CoachInfo',
  mixins: [syncForm],
  components: {
    FormSection,
  },
  props: {
    ...getCoachInfoFormProps(),
  },
};
</script>
