<template>
  <div class="fnol-submit-view fill-height">
    <Preloader :value="organizationPageParams.loading" fullscreen />
    <v-container
      v-if="organizationPageParams.ready"
      class="fnol-submit-view__container"
      fill-height
      align-start
      align-content-start
    >
      <LangToggler />
      <v-row class="fnol-submit-view__header" no-gutters>
        <v-col>
          <div class="fnol-submit-view__header-logo-wrapper">
            <Logo :src="organizationPageParams.logoUrl || '/img/logo/usasa-logo.png'" large />
          </div>
          <div class="fnol-submit-view__header-title-wrapper">
            <PageSubtitle>{{ pageTitle }} {{ $t('messages.usasaTitle') }}</PageSubtitle>
          </div>
        </v-col>
      </v-row>
      <v-row class="fnol-submit-view__body mt-4 mt-md-10" no-gutters>
        <v-col>
          <ClaimFormFysa
            v-bind.sync="form"
            @addHealthInsuranceForm="addHealthInsuranceSubForm"
            @deleteHealthInsuranceForm="deleteHealthInsuranceSubForm"
            @claimsInfoDisclaimerClick="showClaimsInfoDisclaimer"
            @fieldInput="handleFormFieldInput"
            :formParams="formParams"
            ref="form"
          ></ClaimFormFysa>
        </v-col>
      </v-row>
      <v-row class="fnol-submit-view__footer-wrapper my-4 mt-md-6 mb-md-8" no-gutters>
        <v-col cols="12" class="fnol-submit-view__footer">
          <div class="d-flex flex-column flex-md-row justify-md-end">
            <v-btn @click="submitForm" :disabled="submitBtnDisabled">
              {{ $t('ui.submit') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <ClaimsTermsDialog @agree="handleClaimsInfoAgree" v-model="claimsInfoDisclaimerDialogShown" />
    <PhDialogCustom
      :value="showDialogOnSubmit"
      @download="handleDownloadFnol"
      @close="handleCloseDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { fileUpload, scrollUtils, responseDialogs, claimForms, loadingState } from '@/mixins';
import ClaimFormFysa from '@/components/forms/ClaimFormFysa';
import ClaimsTermsDialog from '@/components/modals/ClaimsTermsDialog';
import LangToggler from '@/components/LangToggler';
import Preloader from '@/components/Preloader';
import Logo from '@/components/Logo';
import PageSubtitle from '@/components/typography/PageSubtitle';
import PhDialogCustom from '@/components/modals/PhDialogCustom';

// import { isValidString } from '@/utils/helpers/string';
import {
  USASA_CLAIM_FORM_FIELD_ALIASES,
  INFO_SCREEN_TYPES_PARAMS,
  INFO_SCREEN_TYPES,
  THANK_YOU_TYPES,
  THANK_YOU_TYPES_PARAMS,
} from '@/utils/constants';

const CLAIMANT_FIELD_ALIASES = USASA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT;
const HEALTH_FIELD_ALIASES = USASA_CLAIM_FORM_FIELD_ALIASES.HEALTH;
const PROVIDER_FIELD_ALIASES = USASA_CLAIM_FORM_FIELD_ALIASES.PROVIDER;
const DISCLAIMERS_FIELD_ALIASES = USASA_CLAIM_FORM_FIELD_ALIASES.DISCLAIMERS;
const INCIDENT_FIELD_ALIASES = USASA_CLAIM_FORM_FIELD_ALIASES.INCIDENT;
const COACH_FIELD_ALIASES = USASA_CLAIM_FORM_FIELD_ALIASES.COACH;
const FORM_LOADING_STATE_NAMESPACE = 'FNOLForm';

export default {
  name: 'FNOLSubmitFysa',
  mixins: [
    loadingState,
    fileUpload,
    responseDialogs,
    claimForms,
    scrollUtils({
      yOffset: 20,
    }),
  ],
  props: {
    pageTitle: {
      type: String,
    },
    organizationPageParams: {
      type: Object,
      required: true,
    },
  },
  components: {
    LangToggler,
    ClaimFormFysa,
    ClaimsTermsDialog,
    Preloader,
    Logo,
    PageSubtitle,
    PhDialogCustom,
  },
  data() {
    return {
      claimsInfoDisclaimerDialogShown: false,
      showDialogOnSubmit: false,
      form: {
        // claimant info
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_FIRST_NAME]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_LAST_NAME]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_DOB]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_GENDER]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_COACH_PLAYER_TYPE]: 'PLAYER',
        // part two
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STREET]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_ADDITIONAL]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_CITY]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STATE]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_ZIP]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_COUNTRY]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_EMAIL]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_HOME_PHONE]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_WORK_PHONE]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_STATUS]: 'SINGLE',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_SEEN_PROVIDER]: true,
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_MEDICAID_BENEFICIARY]: false,
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_SS_NOR_HID_NUMBER]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_CURRENTLY_ENROLLED_ACCIDENT_PLAN]: true,
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_PARENTS_PHONE]: '',
        // coach info
        // disclaimers
        [DISCLAIMERS_FIELD_ALIASES.READ_AND_AGREE_TO_CLAIMS_INFO]: false,
        [COACH_FIELD_ALIASES.STATE_ADMIN]: null,
        [COACH_FIELD_ALIASES.COACH_EMAIL]: '',
        [COACH_FIELD_ALIASES.COACH_FIRST_NAME]: '',
        [COACH_FIELD_ALIASES.COACH_LAST_NAME]: '',
        // incident details
        [INCIDENT_FIELD_ALIASES.INCIDENT_NOTE]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_DATE]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_TIME]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_LOCATION]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_BODY_PART_INJURED]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_BODY_SIDE_INJURED]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_TYPE]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_OCCURED_ON]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_OCCURED_AT]: '',
        // INCIDENT_INJURY_OCCURED_AT: 'incidentInjuryOccuredAt',
        // INCIDENT_INJURY_OCCURED_AT_OTHER: 'incidentInjuryOccuredAtOther',
        [INCIDENT_FIELD_ALIASES.INCIDENT_EVENT_NAME_AND_TYPE]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_DESCRIPTION]: '',
        [INCIDENT_FIELD_ALIASES.INCEDENT_NAME_OF_TEAM]: '',
        [INCIDENT_FIELD_ALIASES.INCEDENT_STATE_ASSICOATION_AFFILATE]: '',
        [INCIDENT_FIELD_ALIASES.INCEDENT_REGION]: '',
        [INCIDENT_FIELD_ALIASES.INCEDENT_CLAIMANT_CHECKBOX]: false,
        [INCIDENT_FIELD_ALIASES.INCEDENT_CLAIMANT_SIGNATURE]: '',
        // health insurance info
        healthInsuranceForms: {},
        [HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_BURSAR_EMAIL_ADDRESS]: '',
        // provider info
        providerInformation: {
          [PROVIDER_FIELD_ALIASES.NAME]: '',
          [PROVIDER_FIELD_ALIASES.ADDRESS]: '',
          [PROVIDER_FIELD_ALIASES.EMAIL]: '',
          [PROVIDER_FIELD_ALIASES.PHONE_NUMBER]: '',
        },
      },
    };
  },
  mounted() {
    if (['FYSA'].includes(this.formParams.form)) {
      Object.assign(this.form, {
        [INCIDENT_FIELD_ALIASES.INCEDENT_STATE_ASSICOATION_AFFILATE]: 'FYSA',
        [INCIDENT_FIELD_ALIASES.INCEDENT_REGION]: 'South Region',
      });
    }
  },
  watch: {
    [`form.${CLAIMANT_FIELD_ALIASES.CLAIMANT_CURRENTLY_ENROLLED_ACCIDENT_PLAN}`]: {
      handler(newVal) {
        if (newVal) {
          this.initHealthInsuranceSubForms();
        } else {
          this.resetHealthInsuranceSubForms();
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('claims', ['submittedFnolId']),
    formParams() {
      return {
        loadingFiles: this.fileUpload.loading,
        organizationName: this.organizationPageParams.organizationName,
        stateAdmins: this.organizationPageParams.stateAdmins,
        form: this.organizationPageParams.form,
      };
    },
    submitBtnDisabled() {
      return this.loadingState[FORM_LOADING_STATE_NAMESPACE];
    },
  },
  methods: {
    ...mapActions('claims', ['submitFysaFNOLCreateForm', 'generateClaimPdf']),
    ...mapActions('captcha', ['executeAction']),
    handleDownloadFnol() {
      this.generateClaimPdf(this.submittedFnolId);
      this.showDialogOnSubmit = false;
    },
    handleCloseDialog() {
      this.showDialogOnSubmit = false;
      this.$router.push({
        name: 'infoScreen',
        params: {
          INFO_SCREEN_TYPE: INFO_SCREEN_TYPES_PARAMS[INFO_SCREEN_TYPES.THANK_YOU],
          TYPE: THANK_YOU_TYPES_PARAMS[THANK_YOU_TYPES.FNOL_SUBMIT],
          CLAIMANT_NAME: `${this.form[CLAIMANT_FIELD_ALIASES.CLAIMANT_FIRST_NAME]} ${
            this.form[CLAIMANT_FIELD_ALIASES.CLAIMANT_LAST_NAME]
          }`,
        },
      });
      this.resetForm();
    },

    handleClaimsInfoAgree() {
      this.form[DISCLAIMERS_FIELD_ALIASES.READ_AND_AGREE_TO_CLAIMS_INFO] = true;
      this.hideClaimsInfoDisclaimer();
    },
    showClaimsInfoDisclaimer() {
      this.claimsInfoDisclaimerDialogShown = true;
    },
    hideClaimsInfoDisclaimer() {
      this.claimsInfoDisclaimerDialogShown = false;
    },
    resetForm() {
      if (this.$refs.form && typeof this.$refs.form.reset === 'function') {
        this.$refs.form.reset();
      }
    },
    async submitForm() {
      if (this.$refs.form) {
        const canSubmit = this.$refs.form.submit();
        if (canSubmit) {
          try {
            this.setLoadingState(FORM_LOADING_STATE_NAMESPACE, true);
            const payload = {
              ...this.form,
              sportsUnionId: this.organizationPageParams.organizationId,
            };
            await this.executeAction('submitFNOLFysa');
            const success = await this.submitFysaFNOLCreateForm(payload);
            if (success) {
              this.showDialogOnSubmit = true;
            } else {
              throw new Error('There was en error while submitting your FNOL');
            }
            return success;
          } catch (error) {
            this.$dialog.error({ error });
            return error;
          } finally {
            this.setLoadingState(FORM_LOADING_STATE_NAMESPACE, false);
          }
        } else {
          this.scrollToFirstError();
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.fnol-submit-view {
  background-color: $background-lightest;
}
.fnol-submit-view__container {
  max-width: 780px;
}
.fnol-submit-view__header {
  padding-top: 90px;
}
.fnol-submit-view__header-logo-wrapper {
  display: flex;
  margin-bottom: 40px;
  max-width: 90px;
}
.fnol-submit-view__header-text-wrapper {
  margin-top: 12px;
}
.fnol-submit-view__header-text {
  color: $text-semi-dark;
  font-size: 14px;
  line-height: 1.14;
}
</style>
