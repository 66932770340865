<template>
  <v-container pa-0 fluid>
    <v-row>
      <v-col cols="12" md="12">
        <p class="insurance-radio-text">
          Do you/spouse/parent have medical/health care or is the Claimant enrolled as individual,
          employee or dependent member of a Health Maintenance Organization (HMO) or similar prepaid
          health care plan, or any other type of accident/health/sickness plan coverage through your
          employer or other source on you or does your son/daughter have health care coverage as a
          dependent from your previous marriage as mandated in a divorce decree?
        </p>
        <v-radio-group
          @change="handleFieldInput(INSURANCE_FIELD_ALIASES.CLAIMANT_HAS_HEALTHCARE, arguments[0])"
          :value="this[INSURANCE_FIELD_ALIASES.CLAIMANT_HAS_HEALTHCARE]"
          :rules="['requiredBoolean']"
          row
        >
          <v-radio :value="true" label="Yes"></v-radio>
          <v-radio :value="false" label="No"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-if="this[INSURANCE_FIELD_ALIASES.CLAIMANT_HAS_HEALTHCARE]">
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INSURANCE_FIELD_ALIASES.INSURANCE_COMPANY_NAME, arguments[0])"
          :value="this[INSURANCE_FIELD_ALIASES.INSURANCE_COMPANY_NAME]"
          :rules="['required']"
          label="Insurance company"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="
            handleFieldInput(INSURANCE_FIELD_ALIASES.INSURANCE_POLICYHOLDER_NAME, arguments[0])
          "
          :value="this[INSURANCE_FIELD_ALIASES.INSURANCE_POLICYHOLDER_NAME]"
          :rules="['required']"
          label="Policy holder's name"
        />
      </v-col>
    </v-row>
    <v-row v-if="this[INSURANCE_FIELD_ALIASES.CLAIMANT_HAS_HEALTHCARE]">
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INSURANCE_FIELD_ALIASES.INSURANCE_POLICY_NUMBER, arguments[0])"
          :value="this[INSURANCE_FIELD_ALIASES.INSURANCE_POLICY_NUMBER]"
          :rules="['required', 'memberIdRule']"
          label="Policy number"
          type="text"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INSURANCE_FIELD_ALIASES.INSURANCE_GROUP_NUMBER, arguments[0])"
          :value="this[INSURANCE_FIELD_ALIASES.INSURANCE_GROUP_NUMBER]"
          :rules="['memberIdRule']"
          label="Group number"
          type="text"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { syncForm } from '@/mixins';
import { getInsuranceInfoFormProps } from '@/utils/helpers/app/claimsOlympic';

export default {
  name: 'InsuranceInfo',
  mixins: [syncForm],
  props: {
    ...getInsuranceInfoFormProps(),
  },
};
</script>

<style lang="scss" scoped>
.insurance-radio-text {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 1px;
  color: #5c636c;
}
</style>
