<template>
  <v-container pa-0 fluid>
    <v-row>
      <v-col cols="12" md="12">
        <p class="insurance-radio-text">
          Do you/spouse/parent have medical/health care or are you enrolled as an individual,
          employee or dependent member of a Health Maintenance Organization (HMO) or similar prepaid
          health care plan, or any other type of accident/health/sickness plan coverage through an
          employer, a parent’s employer or other source?
        </p>
        <v-radio-group
          @change="
            handleFieldInput(HEALTH_FIELD_ALIASES.HEALTH_CLAIMANT_HAS_HEALTHCARE, arguments[0])
          "
          :value="this[HEALTH_FIELD_ALIASES.HEALTH_CLAIMANT_HAS_HEALTHCARE]"
          :rules="['requiredBoolean']"
          row
        >
          <v-radio :value="true" label="Yes"></v-radio>
          <v-radio :value="false" label="No"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { syncForm } from '@/mixins';
import { getInsuranceInfoFormProps } from '@/utils/helpers/app/claimsNagha';

export default {
  name: 'InsuranceInfo',
  mixins: [syncForm],
  props: {
    ...getInsuranceInfoFormProps(),
  },
};
</script>

<style lang="scss" scoped>
.insurance-radio-text {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 1px;
  color: #5c636c;
}
</style>
