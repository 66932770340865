<template>
  <v-dialog
    @input="updateDialogValue"
    :value="value"
    :fullscreen="false"
    :max-width="500"
    :transition="transition"
  >
    <v-container
      :fluid="fluid"
      fill-height
      flex-column
      flex-nowrap
      pa-4
      pa-md-10
      class="ph-dialog__container"
    >
      <div v-if="dismissible" class="ph-dialog__close-button-wrapper">
        <CloseBtn @click="updateDialogValue(false)" />
      </div>
      <div ref="contentScroll" class="ph-dialog__content-scroll">
        <div class="ph-dialog__content-wrapper">
          <div class="dialog-response-tricked">
            <SvgIcon :icon="'list'" />
          </div>
          <div class="ph-dialog__content ph-dialog__content--center">
            <h3>You've successfully submitted a FNOL</h3>
          </div>
          <div class="ph-dialog__content ph-dialog__content--center">
            <div class="ph-dialog__print-row" @click="handleDownload">
              <img class="ph-dialog__pdf" src="@/assets/img/Pdf.png" alt="" />
              <div class="ph-dialog__content__text-print">
                Print FNOL information
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ph-dialog__footer pt-4 pt-md-10">
        <v-btn @click.stop="updateDialogValue(false)">
          OK
        </v-btn>
      </div>
    </v-container>
  </v-dialog>
</template>

<script>
import CloseBtn from '@/components/buttons/CloseBtn';

const NAME = 'PhDialog';

export default {
  name: NAME,
  components: {
    CloseBtn,
  },
  props: {
    value: Boolean,
    dismissible: {
      type: Boolean,
      default: true,
    },
    fluid: Boolean,
    transition: {
      type: String,
      default: 'dialog-transition',
    },
  },
  data() {
    return {};
  },
  methods: {
    updateDialogValue(newVal) {
      if (newVal) {
        this.$emit('open');
      } else {
        this.$emit('close');
      }
      this.$emit('input', newVal);
    },
    handleDownload() {
      this.$emit('download');
    },
    handleEscClick(e) {
      if (e.keyCode === 27) {
        this.updateDialogValue(false);
      }
    },
    setCloseOnEscListener() {
      document.addEventListener('keydown', this.handleEscClick);
    },
    removeCloseOnEscListener() {
      document.removeEventListener('keydown', this.handleEscClick);
    },
  },
  mounted() {
    this.setCloseOnEscListener();
  },
  beforeDestroy() {
    this.removeCloseOnEscListener();
  },
};
</script>

<style lang="scss">
@import '~@/scss/base/index';
.ph-dialog__container {
  position: relative;
  background: #fff;
}
.ph-dialog__body {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: $background-lightest;
  &:not(.v-dialog--fullscreen) {
    border-radius: 8px;
  }
  &.v-dialog--fullscreen {
    height: 100%;
  }
  &--narrow {
    .ph-dialog__content {
      max-width: 880px;
      margin: 0 auto;
    }
  }
  &--grow-content {
    .ph-dialog__content {
      height: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    }
  }
  &--vertical-center-content {
    .ph-dialog__container {
      justify-content: center;
    }
    .ph-dialog__content-wrapper {
      flex: 0 1 auto;
      .ph-dialog__content {
        padding-bottom: 10px;
      }
    }
  }
}
.ph-dialog__header {
  text-align: center;
  width: 100%;
  .ph-dialog__heading {
    margin-bottom: 0;
    font-weight: 700;
    color: $text-dark;
  }
}
.ph-dialog__content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}
.ph-dialog__content-scroll {
  flex: 1;
  overflow-y: auto;
  &--shadow {
    position: relative;
    pointer-events: none;
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 115px;
      position: fixed;
      top: 0px;
      left: 0px;
      background: linear-gradient(0deg, $transparent, $background-lightest);
      opacity: 0;
      z-index: 1;
    }
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 115px;
      position: fixed;
      bottom: 0px;
      left: 0px;
      background: linear-gradient(180deg, $transparent, $background-lightest);
      opacity: 0;
      z-index: 1;
    }
  }
  &--shadow-top {
    &::before {
      opacity: 1;
    }
  }
  &--shadow-bottom {
    position: relative;
    &::after {
      opacity: 1;
    }
  }
}
.ph-dialog__content {
  background: $background-lightest;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  pointer-events: all;
  font-size: 1.86rem;
  color: $text-semi-darkest;
  &--center {
    text-align: center;
  }
}
.ph-dialog__footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include bp-up(md) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    & > *:nth-child(1n + 2) {
      margin-left: 20px;
    }
  }
}
.ph-dialog__close-button-wrapper {
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 2;
  display: flex;
  align-items: center;
}

.ph-dialog__pdf {
  display: block;
  max-width: 35px;
}

.dialog-response-tricked {
  width: 50px;
  height: 50px;
  object-fit: contain;
  display: block;
  margin: 0 auto 10px;
}

.ph-dialog__print-row {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0 0;
  .ph-dialog__content__text-print {
    margin-left: 8px;
    font-size: 16px;
    font-weight: 500;
  }
}
</style>
